import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useDateTime } from "hooks/useDateTime";
import {
  Mic,
  Videocam,
  EmojiEmotions,
  ScreenShare,
  PhoneDisabled,
  People,
  Message,
  InfoRounded,
  MicOff,
  VideocamOff,
  PanTool,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useVideoControlStyles = makeStyles((theme) => ({
  controlContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    padding: "0.5rem 2rem",
  },
  toggleButtonGroup: {
    display: "flex",
    gap: theme.spacing(1),
    backgroundColor: "#444",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  toggleButton: {
    border: "none",
    borderRadius: theme.shape.borderRadius,
    color: "#fff",
    padding: theme.spacing(1),
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  endCall: {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
}));

const VideoControl = ({
  toggleRaiseHand,
  setControls,
  toggleAudio,
  toggleVideo,
  isAudioMuted,
  isVideoMuted,
  controls,
  isPublic = false,
}) => {
  const classes = useVideoControlStyles();
  const history = useHistory();

  const { time } = useDateTime();
  const handleChange = (event, newControls) => {
    const isRaiseHand = newControls.includes("raise-hand");
    if (isRaiseHand) {
      toggleRaiseHand(true);
    } else if (!isRaiseHand) {
      toggleRaiseHand(false);
    }
    setControls(newControls);
  };

  const handleEndCall = () => {
    isPublic
      ? history.push("/", { replace: true })
      : history.push("/admin/TeacherSupport");
  };
  return (
    <Box className={classes.controlContainer}>
      <div>
        <span>{time}</span>
        <span> | teacher-support</span>
      </div>
      <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          color="primary"
          value={controls}
          onChange={handleChange}
        >
          <ToggleButton
            className={classes.toggleButton}
            onClick={toggleAudio}
            value="mic"
          >
            {isAudioMuted ? <MicOff /> : <Mic />}
          </ToggleButton>
          <ToggleButton
            className={classes.toggleButton}
            onClick={toggleVideo}
            value="cam"
          >
            {isVideoMuted ? <VideocamOff /> : <Videocam />}
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value="emoji">
            <EmojiEmotions />
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value="share-screen">
            <ScreenShare />
          </ToggleButton>
          <ToggleButton className={classes.toggleButton} value="raise-hand">
            <PanTool />
          </ToggleButton>
        </ToggleButtonGroup>
        <Button className={classes.endCall} onClick={handleEndCall}>
          <PhoneDisabled />
        </Button>
      </Box>

      <Box style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <ToggleButton className={classes.toggleButton} value="info">
          <InfoRounded />
        </ToggleButton>

        <ToggleButton className={classes.toggleButton} value="people">
          <People />
        </ToggleButton>

        <ToggleButton className={classes.toggleButton} value="message">
          <Message />
        </ToggleButton>
      </Box>
    </Box>
  );
};

export default VideoControl;
