import React from "react";
import {
  Typography,
  Avatar,
  Button,
  Chip,
  makeStyles,
  Paper,
  styled,
  Box,
} from "@material-ui/core";
import {
  Mic,
  MicOff,
  PhoneDisabled,
  SupervisedUserCircleRounded,
  Videocam,
  VideocamOff,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "#b0b0b0",
  padding: theme.spacing(2),
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100dvw",
    height: "100dvh",
    display: "grid",
    gridTemplateColumns: "4fr 8fr", // 4:8 ratio
    overflow: "hidden",
  },
  leftPanel: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    overflow: "auto",
  },
  rightPanel: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.black,
  },
  header: {
    "& .MuiTypography-root": {
      fontSize: "1.75rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },
  meetingDetails: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  detailsTitle: {
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  detailText: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  largeAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  userDetails: {
    flex: 1,
  },
  userName: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  endCall: {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
  iconButton: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: "50%",
    "& svg": {
      width: 24,
      height: 24,
    },
  },
  chipGroup: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  joinButton: {
    marginTop: "auto",
    padding: theme.spacing(1.5),
    fontSize: "1.1rem",
  },
  videoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoElement: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "scaleX(-1)",
  },
  videoMessage: {
    color: theme.palette.grey[400],
    fontSize: "1.2rem",
  },
  videoAvatar: {
    width: 200,
    height: 200,
    fontSize: "4rem",
    backgroundColor: "#8b4c70",
  },
}));

const PreMeetingScreenWithoutRef = (props, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [controls, setControls] = React.useState(() => ["mic", "cam"]);

  const {
    isAudioMuted,
    toggleAudio,
    toggleVideo,
    isVideoMuted,
    meetingDetails,
    isPublic,
    publicUser,
  } = props;

  const queryParams = new URLSearchParams(location.search);
  const meetingId = queryParams.get("ready");

  const userDetail = isPublic
    ? { full_name: publicUser?.full_name, email: "" }
    : JSON.parse(localStorage.getItem("user"));

  const handleChange = (event, newControls) => {
    setControls(newControls);
  };

  const handleJoinMeeting = () => {
    isPublic
      ? history.push(`/public/meet?join=${meetingId}&userId=${publicUser?.id}`)
      : history.push(`/admin/meet?join=${meetingId}`);
  };

  const handleEndCall = () => {
    isPublic
      ? history.push(`/`, { replace: true })
      : history.push("/admin/TeacherSupport");
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftPanel}>
        <div className={classes.header}>
          <Typography variant="h1">Pre-Meeting Check</Typography>
        </div>

        <Paper className={classes.meetingDetails}>
          <Typography className={classes.detailsTitle}>
            Meeting Details
          </Typography>
          <Typography className={classes.detailText}>
            Meeting ID: {meetingDetails?.meeting_id}
          </Typography>
          <Typography className={classes.detailText}>
            Host: English Hub
          </Typography>
          <Typography
            className={classes.detailText}
            style={{ display: "flex", alignItems: "start", gap: "0.3rem" }}
          >
            Subject: {meetingDetails?.name}
          </Typography>
        </Paper>

        <Paper className={classes.userInfo}>
          <Avatar
            className={classes.largeAvatar}
            src="/placeholder.svg?height=64&width=64"
          >
            <SupervisedUserCircleRounded />
          </Avatar>
          <div className={classes.userDetails}>
            <Typography className={classes.userName} variant="subtitle1">
              {userDetail?.full_name}
            </Typography>
            <Typography className={classes.detailText}>
              {userDetail?.email}
            </Typography>
          </div>
        </Paper>

        <Paper className={classes.controlsContainer}>
          <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <StyledToggleButtonGroup
              color="primary"
              value={controls}
              onChange={handleChange}
            >
              <StyledToggleButton onClick={toggleAudio} value="mic">
                {isAudioMuted ? <MicOff /> : <Mic />}
              </StyledToggleButton>
              <StyledToggleButton onClick={toggleVideo} value="cam">
                {isVideoMuted ? <VideocamOff /> : <Videocam />}
              </StyledToggleButton>
            </StyledToggleButtonGroup>
            <Button className={classes.endCall} onClick={handleEndCall}>
              <PhoneDisabled />
            </Button>
          </Box>

          <div className={classes.chipGroup}>
            <Chip
              label={`Microphone: ${isAudioMuted ? "Off" : "On"}`}
              color={isAudioMuted ? "default" : "primary"}
              size="medium"
            />
            <Chip
              label={`Camera: ${isVideoMuted ? "Off" : "On"}`}
              color={isVideoMuted ? "default" : "primary"}
              size="medium"
            />
          </div>
        </Paper>

        <Button
          variant="contained"
          color="primary"
          className={classes.joinButton}
          onClick={handleJoinMeeting}
        >
          Join Meeting
        </Button>
      </div>

      <div className={classes.rightPanel}>
        <div className={classes.videoContainer}>
          {isVideoMuted ? (
            <Typography className={classes.videoMessage}>
              <Avatar color="primary" className={classes.videoAvatar}>
                {userDetail?.full_name
                  .split(" ")
                  .map((name) => name[0])
                  .join("")}
              </Avatar>
            </Typography>
          ) : (
            <video
              ref={ref}
              autoPlay
              muted
              playsInline
              className={classes.videoElement}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PreMeetingScreen = React.forwardRef(PreMeetingScreenWithoutRef);

export default PreMeetingScreen;
