import React from "react";
import PublicRemoteServices from "RemoteServices/PublicRemoteServices";

const useFetchMeeting = ({ meetingId }) => {
  const [meeting, setMeeting] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchMeeting(meetingId) {
      try {
        setIsLoading(true);
        if (meetingId === null) {
          return;
        }
        const data = await PublicRemoteServices.getMeetingLink(meetingId);
        if (data) {
          setMeeting(data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchMeeting(meetingId);
  }, [meetingId]);

  return { meeting, isLoading };
};

export default useFetchMeeting;
