import React from "react";
import {
  Container,
  Typography,
  makeStyles,
  Paper,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
    margin: "0 auto",
    width: "100%",
  },
  icon: {
    fontSize: 64,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  message: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
}));

const ErrorLayout = ({ title, message, children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <ErrorIcon className={classes.icon} />
        <Typography variant="h4" component="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
        <div className={classes.actions}>{children}</div>
      </Paper>
    </Container>
  );
};

export default ErrorLayout;
