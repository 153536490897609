import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import ErrorLayout from "./ErrorLayout";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 120,
  },
}));

const ExpiredMeetingToken = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleRequestAccess = () => {
    // Implement your access request logic here
    console.log("Access requested");
  };

  const handleReturnHome = () => {
    history.push("/", { replace: true });
  };

  return (
    <ErrorLayout
      title="Meeting Access Restricted"
      message="This meeting token is no longer valid. It might have expired or be restricted to authorized participants only."
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleRequestAccess}
        className={classes.button}
      >
        Request Access
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleReturnHome}
        className={classes.button}
      >
        Return Home
      </Button>
    </ErrorLayout>
  );
};

export default ExpiredMeetingToken;
