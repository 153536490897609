const axios = require("axios");

const remote = {
  address: process.env.REACT_APP_BASE_URL,
};

function getRequests(api) {
  const apiUrl = remote.address + api;
  let promise = new Promise((resolve, reject) => {
    axios
      .get(apiUrl)
      .then((res) => {
        var data = res.data;
        resolve(data);
      })
      .catch((error) => {
        var errorMessage = error.response.data.Message;
        reject(errorMessage);
      });
  });
  return promise;
}
function postRequests(api, data) {
  const apiUrl = remote.address + api;
  let promise = new Promise((resolve, reject) => {
    axios
      .post(apiUrl, data)
      .then((res) => {
        var data = res.data;
        resolve(data);
      })
      .catch((error) => {
        var errorMessage =
          error?.response?.data?.Message ||
          error?.response?.data ||
          error?.response ||
          "Error Message Not Handled";
        reject(errorMessage);
      });
  });
  return promise;
}

var PublicRemoteServices = {
  getLink: function (id) {
    const url = `link/?unique_id=${id}`;
    return getRequests(url);
  },
  getMeetingLink: function (meetingId) {
    const url = `chat/meeting-link/${meetingId}/`;
    return getRequests(url);
  },
  postPublicMeeting: function (data) {
    const url = `chat/public-meeting/`;
    return postRequests(url, data);
  },
  getPublicMeeting: function (userId) {
    const url = `chat/public-meeting/${userId}`;
    return getRequests(url);
  },
};

module.exports = PublicRemoteServices;
