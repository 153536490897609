import React from "react";
import PublicRemoteServices from "RemoteServices/PublicRemoteServices";

const useFetchPublicUser = ({ userId }) => {
  const [publicUser, setPublicUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchPublicUser(userId) {
      try {
        setIsLoading(true);
        if (!userId) {
          return null;
        }
        const data = await PublicRemoteServices.getPublicMeeting(userId);
        if (data) {
          setPublicUser(data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchPublicUser(userId);
  }, [userId]);
  return { publicUser, isLoading };
};

export default useFetchPublicUser;
