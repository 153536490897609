import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Container,
  Grid,
  Box,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { Link, Person, Videocam } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core";
import RemoteServices from "RemoteServices/RemoteServices";
import EmptyMeetingScreen from "../../examples/EmptyMeetingScreen";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  card: {
    margin: "20px auto",
    padding: theme.spacing(2),
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  publicLabel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: "#43a047",
    "& svg": {
      fontSize: "1.2rem",
    },
  },
  meetingId: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  participants: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  timeInfo: {
    textAlign: "right",
    color: theme.palette.text.secondary,
  },
  accessibleChip: {
    backgroundColor: "#e8f5e9",
    color: "#2e7d32",
    fontWeight: 500,
    borderRadius: 16,
  },
  actions: {
    marginTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
  },
  joinButton: {
    backgroundColor: "#4285f4",
    color: "white",
    textTransform: "none",
    fontSize: "1.2rem",
    width: "100%",
    padding: "8px 24px",
    "&:hover": {
      backgroundColor: "#3367d6",
    },
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1),
    },
  },
}));

const MeetingCards = () => {
  const classes = useStyles();
  const [meetings, setMeetings] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    async function fetchMeetings() {
      try {
        setIsLoading(true);
        const data = await RemoteServices.getMeetingLinks();
        if (data) {
          setMeetings(data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    }
    fetchMeetings();
  }, [refetch]);

  return (
    <Container className={classes.root}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        className={classes.title}
      >
        Available Meeting Rooms
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : meetings & meetings?.length ? (
          <EmptyMeetingScreen />
        ) : (
          meetings?.map((meeting, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper elevation={3}>
                <MeetingCard
                  {...{
                    meeting,
                  }}
                />
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

const MeetingCard = ({ meeting }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleMeetingRedirect = () => {
    history.push(`/admin/meet?ready=${meeting?.id}`);
  };

  function formatMeetingTime(isoString) {
    const date = new Date(isoString);
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date
    );

    return `${formattedDate} at ${formattedTime}`;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.header}>
          <div className={classes.cardTitle}>
            <Typography variant="h5" style={{ textTransform: "capitalize" }}>
              {meeting.name.length > 12
                ? `${meeting.name.slice(0, 12)}...`
                : meeting.name}
            </Typography>
          </div>
          <Chip label="Available" className={classes.accessibleChip} />
        </div>

        <div className={classes.meetingId}>
          <Link fontSize="small" />
          <Typography>{meeting.meeting_id}</Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={classes.participants}>
            <Person fontSize="small" />
            <Typography>0 participants</Typography>
          </div>
          <Typography className={classes.timeInfo}>
            {formatMeetingTime(meeting.meeting_start_time)}
          </Typography>
        </div>

        <div className={classes.actions}>
          <div className={classes.rightSection}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleMeetingRedirect(meeting.meeting_id)}
              className={classes.joinButton}
              startIcon={<Videocam />}
            >
              Join Meeting
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MeetingCards;
