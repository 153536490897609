import { Avatar, Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { MicOff, PanTool, Public, School, Stars } from "@material-ui/icons";
import React from "react";

const useVideoCardStyles = makeStyles((theme) => ({
  userContainer: {
    cursor: "pointer",
    shadow: theme.shadows[3],
  },
  videoContainer: {
    backgroundColor: theme.palette.grey[800],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "scaleX(-1)",
  },
  videoMessage: {
    color: theme.palette.grey[400],
    fontSize: "1.2rem",
  },
  videoAvatar: {
    position: "absolute",
    transform: "translateX(-50%) translateY(-50%)",
    width: "8rem",
    height: "8rem",
    fontSize: "2rem",
    backgroundColor: "#8b4c70",
    textTransform: "capitalize",
  },
  nameTag: {
    position: "absolute",
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  muteIndicator: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "white",
    padding: theme.spacing(0.5),
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  handRaiseIndicator: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: "rgba(255, 193, 7, 0.8)",
    color: "black",
    padding: theme.spacing(0.5),
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    animation: "$pulse 1.5s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 0 rgba(255, 193, 7, 0.4)",
    },
    "70%": {
      transform: "scale(1.1)",
      boxShadow: "0 0 0 10px rgba(255, 193, 7, 0)",
    },
    "100%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 0 rgba(255, 193, 7, 0)",
    },
  },
  userTypeIcon: {
    fontSize: "1rem",
  },
  admin: {
    border: "2px solid #6e45e2",
    boxShadow: "0 2px 8px rgba(110, 69, 226, 0.15)",
  },
  student: {
    border: "2px solid #f6d365",
    boxShadow: "0 2px 8px rgba(246, 211, 101, 0.15)",
  },
  public: {
    border: "2px solid #84fab0",
    boxShadow: "0 2px 8px rgba(132, 250, 176, 0.15)",
  },
}));

const VideoCard = React.forwardRef(
  ({ isVideoMuted, userName, isRaisingHand, isAudioMuted, userType }, ref) => {
    const classes = useVideoCardStyles();
    const getUserType = () => {
      switch (userType) {
        case "admin":
          return {
            className: classes.admin,
            Icon: <Stars className={classes.userTypeIcon} />,
          };
        case "student":
          return {
            className: classes.student,
            Icon: <School className={classes.userTypeIcon} />,
          };
        default:
          return {
            className: classes.public,
            Icon: <Public className={classes.userTypeIcon} />,
          };
      }
    };
    return (
      <Box className={classes.userContainer}>
        <Paper
          elevation={2}
          className={` ${classes.videoContainer}
    ${getUserType().className}
    `}
        >
          {isVideoMuted ? (
            <Typography className={classes.videoMessage}>
              <Avatar color="primary" className={classes.videoAvatar}>
                {userName
                  .split(" ")
                  .map((name) => name[0])
                  .join("")}
              </Avatar>
            </Typography>
          ) : (
            <video ref={ref} className={classes.video} autoPlay />
          )}
          <Typography variant="body2" className={`${classes.nameTag}`}>
            {getUserType().Icon}
            {userName}
          </Typography>
          {isAudioMuted ? (
            <div className={classes.muteIndicator}>
              <MicOff fontSize="small" />
            </div>
          ) : null}
          {isRaisingHand ? (
            <div className={classes.handRaiseIndicator}>
              <PanTool fontSize="small" />
            </div>
          ) : null}
        </Paper>
      </Box>
    );
  }
);

export default VideoCard;
