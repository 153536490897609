const translationKeys = {
  Dashboard: "dashboard",
  Lessons: "lessons",
  FreeLessons: "freeLesson",
  privacyPolicy: "privacyPolicy",
  About: "about",
  OtherProfile: "otherProfile",
  Profile: "profile",
  Review: "review",
  Community: "community",
  History: "history",
  Notifications: "notifications",
  ReferAndEarn: "referAndEarn",
  TeacherSupport: "teacherSupport",
  MessageUs: "messageUs",
  // Add translation keys for other route names...
};

export default translationKeys;
