import React from "react";
import SimplePeer from "simple-peer";
import useWebSocket from "react-use-websocket";
import { useMediaStream } from "./useMediaStream";
import { useLocation } from "react-router-dom";

export const useWebrtc = ({ isPublic = false, publicUser, meetingId }) => {
  const {
    ref: localVideoRef,
    stream: localStream,
    play,
    unMuteAudio,
    unMuteVideo,
    muteAudio,
    muteVideo,
    isVideoMuted,
    isAudioMuted,
  } = useMediaStream({
    audioDeviceId: undefined,
    videoDeviceId: undefined,
  });

  const userDetail = JSON.parse(localStorage.getItem("user"));
  const userId = isPublic ? publicUser?.id : userDetail?.id;
  const userName = isPublic ? publicUser?.full_name : userDetail?.full_name;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isJoin = queryParams.get("join");

  React.useEffect(() => {
    play();
  }, [play]);

  const peersRef = React.useRef(new Map());

  const [peerList, setPeerList] = React.useState([]);

  const wsUrl = `${process.env.REACT_APP_SOCKET_URL}meeting/${meetingId}-${
    isPublic ? "p" : "a"
  }${userId}/room/`;

  const ws = useWebSocket(
    wsUrl,
    {
      onOpen: () => console.log("Socket connection established"),
      onClose: () => console.log("Socket connection closed"),
    },
    Boolean(userId) && Boolean(meetingId) && Boolean(isJoin)
  );

  React.useEffect(() => {
    const message = ws.lastJsonMessage;

    if (!message) return;

    switch (message.type) {
      case "participants":
        handleConnectedPeers(message.peerIds);
        break;
      case "new_participant":
        handleNewPeer(message.peerId);
        break;
      case "participant_left":
        handlePeerDisconnect(message.peerId);
        break;
      case "signal":
        handleSignal({
          sender: message.sender,
          recipient: message.recipient,
          signal: message.signal,
        });
        break;
      default:
        break;
    }
  }, [ws.lastJsonMessage]);

  const handleConnectedPeers = (peerIds) => {
    peerIds.forEach((peerDetail) => {
      if (
        !peersRef.current.has(peerDetail.id) &&
        String(peerDetail.id) !== String(userId) &&
        !isPeerIdNull(peerDetail.id)
      ) {
        createPeer(peerDetail, true);
      }
    });
  };

  const handleNewPeer = (peerDetail) => {
    if (
      !peersRef.current.has(peerDetail.id) &&
      String(peerDetail.id) !== String(userId) &&
      !isPeerIdNull(peerDetail.id)
    ) {
      createPeer(peerDetail, false);
    }
  };

  const handlePeerDisconnect = (peerDetail) => {
    const peerData = peersRef.current.get(peerDetail.id);
    if (peerData) {
      peerData.peer.destroy();
      peersRef.current.delete(peerDetail.id);
      updatePeerList();
    }
  };

  const handleSignal = ({ sender, recipient, signal }) => {
    if (String(recipient) !== String(userId)) return;
    const normalizedSender = Number(sender);
    const peerData = peersRef.current.get(normalizedSender);
    if (peerData) {
      peerData.peer.signal(signal);
    }
  };

  const createPeer = (peerDetail, initiator) => {
    const peer = new SimplePeer({
      initiator,
      stream: localStream.current || undefined,
      trickle: false,
    });

    peer.on("signal", (data) => {
      ws.sendJsonMessage({
        type: "signal",
        recipient: String(peerDetail.id),
        sender: String(userId),
        signal: data,
      });
    });

    peer.on("stream", (remoteStream) => {
      peersRef.current.set(peerDetail.id, {
        id: peerDetail.id,
        detail: peerDetail,
        peer,
        stream: remoteStream,
      });
      updatePeerList();
    });

    peer.on("connect", () => {
      console.log(`Peer ${peerDetail.id} connected successfully`);
      const peerData = peersRef.current.get(peerDetail.id);
      if (peerData.peer) {
        const data = {
          type: "peerName",
          peerName: userName,
        };
        peerData.peer.write(JSON.stringify(data));
      }
      updatePeerList();
    });

    peer.on("error", (err) => {
      // Error with peer 50843, initiator true: OperationError: User-Initiated Abort, reason=Close called
      console.error(
        `Error with peer ${peerDetail.id}, initiator ${initiator}:`,
        err
      );
      peersRef.current.delete(peerDetail.id);
      updatePeerList();
    });

    peer.on("close", () => {
      peersRef.current.delete(peerDetail.id);
      peer.destroy();
      updatePeerList();
    });

    peersRef.current.set(peerDetail.id, {
      id: peerDetail.id,
      detail: peerDetail,
      peer,
      stream: null,
    });
    updatePeerList();
  };

  const updatePeerList = () => {
    setPeerList(Array.from(peersRef.current.values()));
  };

  const isPeerIdNull = (peerId) => {
    return peerId === "null" || !peerId;
  };

  const toggleAudioVideo = (isMuted, type) => {
    if (!localStream.current) return;

    switch (isMuted) {
      case true:
        muteUnmute(type, true);
        break;
      case false:
        muteUnmute(type, false);
        break;
      default:
        break;
    }
  };

  const muteUnmute = (type, isMuted) => {
    switch (type) {
      case "audio":
        if (isMuted) {
          unMuteAudio();
          sendIsMutedData({
            isVideoMuted: isVideoMuted,
            isAudioMuted: false,
          });
        } else {
          muteAudio();
          sendIsMutedData({
            isVideoMuted: isVideoMuted,
            isAudioMuted: true,
          });
        }
        break;
      case "video":
        if (isMuted) {
          unMuteVideo();
          sendIsMutedData({
            isVideoMuted: false,
            isAudioMuted: isAudioMuted,
          });
        } else {
          muteVideo();
          sendIsMutedData({
            isVideoMuted: true,
            isAudioMuted: isAudioMuted,
          });
        }
        break;
      default:
        break;
    }
  };

  const sendIsMutedData = ({ isVideoMuted, isAudioMuted }) => {
    peersRef.current.forEach(({ peer }) => {
      if (peer) {
        const data = {
          type: "isMuted",
          id: String(userId),
          isVideoMuted,
          isAudioMuted,
        };
        if (peer) {
          peer.write(JSON.stringify(data));
        }
      }
    });
  };

  const toggleVideo = () => {
    toggleAudioVideo(isVideoMuted, "video");
  };

  const toggleAudio = () => {
    toggleAudioVideo(isAudioMuted, "audio");
  };

  const toggleRaiseHand = (isRaiseHand) => {
    peersRef.current.forEach(({ peer }) => {
      if (peer) {
        const data = {
          type: "raiseHand",
          isRaiseHand: isRaiseHand,
          id: String(userId),
        };
        if (peer) {
          peer.write(JSON.stringify(data));
        }
      }
    });
  };

  return {
    localVideoRef,
    peersRef,
    peerList,
    toggleAudio,
    toggleVideo,
    toggleRaiseHand,
    isVideoMuted,
    isAudioMuted,
  };
};
