import React from "react";
import { useLocation } from "react-router-dom";
import PublicMeetingJoin from "./PublicMeetingJoin";
import useFetchMeeting from "../services/fetch-meeting";
import Loading from "components/Loading/Loading";
import ExpiredMeetingToken from "../components/ExpiredMeeting";
import InvalidMeetingToken from "../components/InvalidMeeting";
import useFetchPublicUser from "../services/fetch-public-user";

export default function PublicMeeting() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMeetingId = queryParams.get("meetingId");
  const isReady = queryParams.get("ready");
  const isJoin = queryParams.get("join");
  const publicUserId = queryParams.get("userId");
  const { publicUser, isLoading: isPublicUserLoading } = useFetchPublicUser({
    userId: publicUserId,
  });

  const { meeting, isLoading } = useFetchMeeting({
    meetingId: isMeetingId || isReady || isJoin,
  });

  const showInvalidMeeting =
    !isMeetingId && !isReady && !publicUserId && !isJoin && !publicUserId;

  if (showInvalidMeeting) {
    return <InvalidMeetingToken />;
  }
  if (isLoading || isPublicUserLoading) {
    return <Loading />;
  }
  const meetingIsPublic =
    meeting?.meeting_type === "public" || meeting?.meeting_type === "both";

  if (meetingIsPublic) {
    if (isReady || isJoin) {
      if (!publicUser) {
        return <ExpiredMeetingToken />;
      }
    }
  }

  return (
    <>
      <PublicMeetingJoin
        isReady={isReady}
        meetingId={isMeetingId}
        isJoin={isJoin}
        meeting={meeting}
        publicUser={publicUser}
      />
    </>
  );
}
