import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  makeStyles,
  Paper,
  Divider,
  Link,
  Box,
} from "@material-ui/core";
import {
  VideoCall as VideoCallIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import PublicRemoteServices from "RemoteServices/PublicRemoteServices";
import { useWebrtc } from "hooks/useWebrtc";
import PreMeetingScreen from "../components/PreMeetingScreen";
import MeetingRoom from "../components/MeetingRoom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
  headerIcon: {
    fontSize: 40,
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "500px",
    margin: "0 auto",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  infoBox: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(3, 0),
    width: "100%",
  },
  footer: {
    marginTop: "auto",
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

const PublicMeetingJoin = ({
  meetingId,
  meeting,
  isReady,
  isJoin,
  publicUser,
}) => {
  const {
    localVideoRef,
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    toggleRaiseHand,
    peersRef,
  } = useWebrtc({
    isPublic: true,
    publicUser: publicUser,
    meetingId: meeting?.meeting_id,
  });
  const classes = useStyles();
  const [isPostLoading, setIsPostLoading] = React.useState(false);
  const [fullName, setFullName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = fullName.trim();
    if (name.length < 3) {
      enqueueSnackbar("Full name must be at least 3 characters long", {
        variant: "error",
      });
      return;
    }
    try {
      setIsPostLoading(true);
      const result = await PublicRemoteServices.postPublicMeeting({
        full_name: name,
        control: false,
      });
      history.push(`/public/meet?ready=${meetingId}&userId=${result?.id}`);
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    } finally {
      setIsPostLoading(false);
    }
  };

  if (isReady && !isJoin) {
    return (
      <PreMeetingScreen
        ref={localVideoRef}
        {...{
          isAudioMuted,
          toggleAudio,
          toggleVideo,
          isVideoMuted,
          isPublic: true,
          publicUser: publicUser,
          meetingDetails: meeting,
        }}
      />
    );
  }

  if (isJoin) {
    return (
      <MeetingRoom
        {...{
          peersRef,
          localVideoRef,
          isAudioMuted,
          isVideoMuted,
          toggleAudio,
          toggleVideo,
          toggleRaiseHand,
          isPublic: true,
          publicUser: publicUser,
        }}
      />
    );
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <VideoCallIcon className={classes.headerIcon} color="primary" />
        <Typography variant="h4" component="h1">
          VirtualMeet
        </Typography>
      </Box>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5" component="h2" className={classes.title}>
          Join English Hub Meeting
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Enter your name to join the english hub meeting room
        </Typography>
        <Box className={classes.infoBox}>
          <InfoIcon className={classes.infoIcon} />
          <Typography variant="body2">
            This is a public meeting. Anyone with the link can join.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            autoFocus
            margin="normal"
            id="fullName"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            helperText="This name will be visible to other participants"
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled={isPostLoading}
            size="large"
          >
            Join Meeting
          </Button>
        </form>
        <Divider className={classes.divider} />
        <Typography variant="body2" color="textSecondary">
          By joining, you agree to our <Link href="#">Terms of Service</Link>{" "}
          and <Link href="#">Privacy Policy</Link>.
        </Typography>
      </Paper>
      <footer className={classes.footer}>
        <Typography variant="body2" color="textSecondary">
          © 2023 English Hub. All rights reserved.
        </Typography>
        <Typography variant="body2">
          <Link href="#" color="inherit">
            Help
          </Link>{" "}
          |{" "}
          <Link href="#" color="inherit">
            Contact Us
          </Link>
        </Typography>
      </footer>
    </Container>
  );
};

export default PublicMeetingJoin;
