import { useWebrtc } from "hooks/useWebrtc";
import React from "react";
import { useLocation } from "react-router-dom";
import PreMeetingScreen from "../components/PreMeetingScreen";
import MeetingRoom from "../components/MeetingRoom";
import useFetchMeeting from "../services/fetch-meeting";
import Loading from "components/Loading/Loading";
import ExpiredMeetingToken from "../components/ExpiredMeeting";

const PrivateMeeting = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isReady = queryParams.get("ready");
  const isJoin = queryParams.get("join");

  const { meeting, isLoading } = useFetchMeeting({
    meetingId: isReady || isJoin,
  });
  const {
    localVideoRef,
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    toggleRaiseHand,
    peersRef,
  } = useWebrtc({ meetingId: meeting?.meeting_id });

  if (isLoading) {
    return <Loading />;
  }

  if (!meeting?.is_visible_to_student) {
    return <ExpiredMeetingToken />;
  }

  if (isReady && !isJoin) {
    return (
      <PreMeetingScreen
        ref={localVideoRef}
        {...{
          isAudioMuted,
          toggleAudio,
          toggleVideo,
          isVideoMuted,
          meetingDetails: meeting,
        }}
      />
    );
  }

  return (
    <>
      <MeetingRoom
        {...{
          peersRef,
          localVideoRef,
          isAudioMuted,
          isVideoMuted,
          toggleAudio,
          toggleVideo,
          toggleRaiseHand,
        }}
      />
    </>
  );
};

export default PrivateMeeting;
