import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ErrorLayout from "./ErrorLayout";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 120,
  },
}));

const InvalidMeetingToken = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleRequestHelp = () => {
    // Implement your help request logic here
    console.log("Help requested");
  };

  return (
    <ErrorLayout
      title="Invalid Meeting Link"
      message="The meeting link is either invalid or incomplete. Please check the meeting link or contact the host for the correct link."
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        className={classes.button}
      >
        Go Back
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleRequestHelp}
        className={classes.button}
      >
        Request Help
      </Button>
    </ErrorLayout>
  );
};

export default InvalidMeetingToken;
