import React from "react";
import VideoControl from "./VideoControl";
import PeerVideo from "./PeerVideo";
import VideoCard from "./VideoCard";
import { Box, Container, makeStyles } from "@material-ui/core";
import { useGridLayout } from "hooks/useGridLayout";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    position: "fixed",
    width: "100%",
    inset: "0px",
    backgroundColor: "#333",
    color: "#fff",
  },
  boxContainer: {
    width: "100%",
    maxWidth: "95%",
    maxHeight: "calc(100vh - 64px)",
  },
  box: {
    display: "grid",
    gap: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: theme.spacing(4),
    aspectRatio: "16 / 9",
  },
}));

const MeetingRoom = ({
  peersRef,
  localVideoRef,
  isAudioMuted,
  isVideoMuted,
  toggleAudio,
  toggleVideo,
  toggleRaiseHand,
  isPublic = false,
  publicUser,
}) => {
  const classes = useStyles();

  const [controls, setControls] = React.useState(() => ["mic", "cam"]);

  const gridRef = React.useRef(null);
  const peersWithStream = Array.from(peersRef.current?.values()).filter(
    (peerData) => peerData.stream
  );
  const TOTAL_PEERS = peersWithStream.length + 1;
  const { layout } = useGridLayout(gridRef, TOTAL_PEERS);
  const VISIBLE_PEERS = Math.min(TOTAL_PEERS, layout.maxTiles);

  const userDetail = JSON.parse(localStorage.getItem("user"));
  const userId = isPublic ? publicUser?.id : userDetail?.id;
  const userName = isPublic ? publicUser?.full_name : userDetail?.full_name;
  const isRaisingHand = controls.includes("raise-hand");

  return (
    <Container maxWidth={"xl"} className={classes.container}>
      <Box className={classes.boxContainer}>
        <div
          className={classes.box}
          ref={gridRef}
          style={{
            gridTemplateColumns: `repeat(var(--col-count), 1fr)`,
            gridTemplateRows: `repeat(calc(${Math.ceil(
              VISIBLE_PEERS / "var(--col-count)"
            )}), 1fr)`,
          }}
        >
          {/* local video */}
          <VideoCard
            ref={localVideoRef}
            {...{
              isAudioMuted,
              isVideoMuted,
              isRaisingHand,
              userName,
              userType: isPublic ? "public" : "student",
            }}
          />
          {/* peer videos */}
          {Array.from(peersRef.current?.values()).map((peerData) => {
            if (String(peerData.id) === String(userId) || !peerData.stream) {
              return null;
            }
            return <PeerVideo key={peerData.id} peerData={peerData} />;
          })}
        </div>
      </Box>
      <VideoControl
        {...{
          toggleRaiseHand,
          setControls,
          toggleAudio,
          toggleVideo,
          isAudioMuted,
          isVideoMuted,
          controls,
          isPublic,
        }}
      />
    </Container>
  );
};

export default MeetingRoom;
