import React from "react";
import VideoCard from "./VideoCard";

const PeerVideo = ({ peerData }) => {
  const [raiseHand, setRaiseHand] = React.useState("");
  const [peerMutedDetails, setPeerMutedDetails] = React.useState({});
  const { id, stream, peer } = peerData;

  peer.on("data", (data) => {
    const jsonData = JSON.parse(data);
    if (jsonData.type) {
      switch (jsonData.type) {
        case "isMuted":
          setPeerMutedDetails({
            isVideoMuted: jsonData.isVideoMuted,
            isAudioMuted: jsonData.isAudioMuted,
          });
          break;
        case "raiseHand":
          setRaiseHand(jsonData.isRaiseHand);
          break;
        default:
          break;
      }
    }
  });

  return (
    <>
      <VideoCard
        key={id}
        ref={(videoElement) => {
          if (videoElement && stream) {
            videoElement.srcObject = stream;
          }
        }}
        isVideoMuted={peerMutedDetails?.isVideoMuted}
        isAudioMuted={peerMutedDetails?.isAudioMuted}
        isRaisingHand={raiseHand}
        userName={peerData?.detail?.full_name}
        userType={peerData?.detail?.user_type}
      />
    </>
  );
};

export default PeerVideo;
